import {
  IAddress,
  IFilterContent,
  IPhotoSideEnum,
  IProduct,
  IProductFilterSortByEnum,
  IProductStock,
  ISneaker,
  ICourierEnum,
  IOrderProductStockFilterSortByEnum,
  IUserFilterSortByEnum,
  IStoreFilterSortByEnum,
  IBrand,
} from "api/openApi";

export interface IDropDown {
  value: string;
  name: string;
}

export interface IDropDownFilters {
  value: string;
  name: string;
  isChecked?: boolean;
}

export interface IDropDownItem {
  item: IDropDownFilters;
  isCheckboxVisible?: boolean;
  onClick: (value: IDropDownFilters) => void;
  isSelected?: boolean;
}

export class Product {
  productPictures: ProductPictures = new ProductPictures();
  productDetails: IProduct = new ProductDetails();
}

export class ProductError {
  productPictures: ProductPictures = new ProductPictures();
  productDetails: ProductErrors = new ProductErrors();
}

export class ProductErrors {
  cityId = "";
  productGrade = "";
  description = "";
  productStocks: ProductStockError[] = [];
}

export class ProductStockError {
  boxCondition = "";
  price = "";
  size = "";
  stock = "";
}

export class ProductDetails implements IProduct {
  hasInvoice = false;
  isNegotiable = false;
}

export class ProductPictures {
  [IPhotoSideEnum.Front] = new Picture(0, "Front", IPhotoSideEnum.Front);
  [IPhotoSideEnum.Back] = new Picture(1, "Back", IPhotoSideEnum.Back);
  [IPhotoSideEnum.Left] = new Picture(2, "Left", IPhotoSideEnum.Left);
  [IPhotoSideEnum.Right] = new Picture(3, "Right", IPhotoSideEnum.Right);
  [IPhotoSideEnum.Up] = new Picture(4, "Up", IPhotoSideEnum.Up);
  [IPhotoSideEnum.Sole] = new Picture(5, "Sole", IPhotoSideEnum.Sole);
  [IPhotoSideEnum.InsideTag] = new Picture(
    6,
    "Inside Tag",
    IPhotoSideEnum.InsideTag
  );
  [IPhotoSideEnum.InsideSole] = new Picture(
    7,
    "Inside Sole",
    IPhotoSideEnum.InsideSole
  );
  [IPhotoSideEnum.Box]: Picture = new Picture(
    8,
    "Box",
    IPhotoSideEnum.Box,
    true
  );
  [IPhotoSideEnum.Aditional]: Picture = new Picture(
    9,
    "Additional",
    IPhotoSideEnum.Aditional,
    true
  );
}
export interface IAddProduct {
  product: Product;
  index: number;
}

export interface IInitialProps {
  filterContent?: IFilterContent;
  sneaker?: ISneaker;
  product?: IProduct;
  productStock?: IProductStock;
  addressList?: IAddress[];
  storeData?: any;
  brands?: IBrand[];
  isMobile?: boolean;
}
export class Picture {
  files?: File | File[] = undefined;
  urls?: string | string[] = undefined;
  index: number;
  display: string;
  id: IPhotoSideEnum;
  optional = false;
  exampleUrl =
    "https://media.b1tv.ro/unsafe/1260x709/smart/filters:contrast(5):format(webp):quality(80)/http://www.b1tv.ro/wp-content/uploads/2022/06/Florin-Salam.jpg";

  constructor(
    index: number,
    display: string,
    id: IPhotoSideEnum,
    optional = false
  ) {
    this.index = index;
    this.display = display;
    this.optional = optional;
    this.id = id;
  }
}

export class FilterContent implements IFilterContent {
  availableCities = [];
  availableSizes = [];
  brands = [];
  maxDate = new Date().toDateString();
  maxPrice = 0;
  minDate = new Date().toDateString();
  minPrice = 0;
}

export const ProductSortByList = Object.keys(IProductFilterSortByEnum).map(
  (key) => ({
    name: key,
    // @ts-ignore
    value: IProductFilterSortByEnum[key],
  })
);

export const WishSortByList = [
  {
    name: IProductFilterSortByEnum.Popularity.toString(),
    value: IProductFilterSortByEnum.Popularity,
  },
  {
    name: IProductFilterSortByEnum.ReleaseDate.toString(),
    value: IProductFilterSortByEnum.ReleaseDate,
  },
];

export const CouriersList = Object.keys(ICourierEnum).map((key) => ({
  name: key,
  // @ts-ignore
  value: ICourierEnum[key],
}));

export const OrderProductConditionList = Object.keys(
  IOrderProductStockFilterSortByEnum
).map((key) => ({
  name: key,
  // @ts-ignore
  value: IOrderProductStockFilterSortByEnum[key],
}));

export const UserSortByConditionList = Object.keys(IUserFilterSortByEnum).map(
  (key) => ({
    name: key,
    // @ts-ignore
    value: IUserFilterSortByEnum[key],
  })
);

export const StoreSortByConditionList = Object.keys(IStoreFilterSortByEnum).map(
  (key) => ({
    name: key,
    // @ts-ignore
    value: IStoreFilterSortByEnum[key],
  })
);
