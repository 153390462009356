import axios from "axios";
import Api from "./apiInstances";

export const checkResponse = (response: any) => {
  return response.status === 200 || response.status === 201;
};

export const checkRecaptcha = async function (token: any) {
  const proxyurl = "https://cors-anywhere.herokuapp.com/";
  const url = "https://www.google.com/recaptcha/api/siteverify";
  const body = `?secret=${process.env.REACT_APP_SECRET_KEY}&response=${token}`;
  const reqUrl = proxyurl + url + body;

  const response = await axios.post(reqUrl);

  return response.data;
};

export const registerUser = async (data: any) => {
  try {
    const response = await Api.authenticationApiInstance.register(data);
    return response.data;
  } catch (err) {
    return false;
  }
};
