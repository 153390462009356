import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import ro from "./ro";

const resources = {
  en,
  ro,
};

i18n.use(initReactI18next).init({
  resources,
  // lng: "ro",
  lng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
