import React, {
  createContext,
  useState,
  PropsWithChildren,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  IBrand,
  IFilterContent,
  IProductFilter,
  ISneaker,
  IProductConditionEnum,
  ISneakerListPaged,
  ISizeDTO,
  IProduct,
  IProductStock,
  IAddress,
  IOrder,
} from "api/openApi/api";
import { CreateOrderStatus, ISneakersContext } from "./types";
import {
  FilterContent,
  IAddProduct,
  IInitialProps,
  Product,
  ProductDetails,
  ProductError,
  ProductStockError,
} from "utils/CommonInterfaces";
import { AxiosResponse } from "axios";
import Api from "api/apiInstances";
import { GeneralContext } from "./GeneralContext";
import { useRouter } from "next/router";

export const SneakersContext = createContext({} as ISneakersContext);

const SneakersProvider = ({
  children,
  initialContext,
}: PropsWithChildren<{
  initialContext: IInitialProps;
}>) => {
  const { t } = useContext(GeneralContext);
  const router = useRouter();

  const [sneaker, setSneaker] = useState<ISneaker>(
    initialContext.sneaker || {}
  );
  const [product, setProduct] = useState<IProduct>(
    initialContext.product || {}
  );

  const [addressList, setAddressList] = useState<IAddress[]>(
    initialContext.addressList || []
  );
  const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>(
    undefined
  );

  const [brands, setBrands] = useState<IBrand[] | []>(
    initialContext.brands || []
  );

  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [productStock, setProductStock] = useState<IProductStock>(
    initialContext.productStock || {}
  );
  const [productList, setProductList] = useState<Product[]>([]);
  const [productListError, setProductListError] = useState<ProductError[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<IProductFilter>({});
  const [sneakerListPaged, setSneakerListPaged] = useState<ISneakerListPaged>({
    list: [],
  });
  const [createOrderStatus, setCreateOrderStatus] = useState<{
    status: CreateOrderStatus;
    message?: "";
  }>({ status: CreateOrderStatus.InProgress });

  const [isProductEditMode, setIsProductEditMode] = useState<boolean>(false);

  const [productPicturesModal, setProductPicturesModal] = useState<{
    isOpen: boolean;
    addProduct: IAddProduct;
  }>({
    isOpen: false,
    addProduct: {
      index: 0,
      product: new Product(),
    },
  });

  const [filterContent, setFilterContent] = useState<IFilterContent>(
    initialContext.filterContent || new FilterContent()
  );

  const [sizes, setSizes] = useState<ISizeDTO[]>([]);

  const [brandMap, setBrandMap] = useState<Array<IBrand>>(() => {
    const map: Array<IBrand> = [];
    brands?.forEach((brand) => {
      map[brand.brandId || 0] = brand;
    });
    return map;
  });

  const [fullBrandMap] = useState<Array<IBrand>>(() => {
    const map: Array<IBrand> = [];
    brands?.forEach((brand) => {
      map[brand.brandId || 0] = brand;
    });
    return map;
  });

  const [sizesMap, setSizeMap] = useState<{
    [key: number]: ISizeDTO;
  }>(() => {
    const map: { [key: number]: ISizeDTO } = {};
    initialContext.filterContent?.availableSizes?.forEach((size) => {
      map[size.sizeId || 0] = size;
    });
    return map;
  });

  const [fullSizesMap, setFullSizeMap] = useState<{
    [key: number]: ISizeDTO;
  }>(() => {
    const map: { [key: number]: ISizeDTO } = {};
    sizes.forEach((size) => {
      map[size.sizeId || 0] = size;
    });
    return map;
  });

  useEffect(() => {
    const map: { [key: number]: ISizeDTO } = {};
    sizes.forEach((size) => {
      map[size.sizeId || 0] = size;
    });
    setFullSizeMap(map);
  }, [sizes]);

  useEffect(() => {
    const map: { [key: number]: ISizeDTO } = {};
    filterContent.availableSizes?.forEach((size) => {
      map[size.sizeId || 0] = size;
    });
    setSizeMap(map);
  }, [filterContent.availableSizes]);

  useEffect(() => {
    const map: Array<IBrand> = [];
    filterContent.brands?.forEach((brand) => {
      map[brand.brandId || 0] = brand;
    });
    setBrandMap(map);
  }, [filterContent.brands]);

  const addProduct = (condition: IProductConditionEnum) => {
    setProductList((productList) => [
      ...productList,
      {
        ...new Product(),
        productDetails: {
          ...new ProductDetails(),
          condition,
          sneakerId: sneaker.sneakerId,
          productGrade:
            condition === IProductConditionEnum.Used ? undefined : 10,
          productStocks: [
            {
              stock: condition === IProductConditionEnum.Used ? 1 : 0,
            },
          ],
        },
      },
    ]);
  };

  const removeProduct = (index: number) => {
    setProductList((productList) => {
      const newList = [...productList];
      newList.splice(index, 1);
      return newList;
    });
  };

  const validateProductList = useCallback(() => {
    const errors: ProductError[] = [];
    let hasError = false;
    productList.forEach((product) => {
      const productError: ProductError = new ProductError();
      if (!product.productDetails?.productGrade) {
        productError.productDetails.productGrade = t("errors.requiredField");
        hasError = true;
      }
      if (
        !product.productDetails?.city ||
        !product.productDetails?.city.cityId
      ) {
        productError.productDetails.cityId = t("errors.requiredField");
        hasError = true;
      }
      product.productDetails?.productStocks?.forEach((stock) => {
        const productStockError = new ProductStockError();

        if (!stock.stock) {
          productStockError.stock = t("errors.requiredField");
          hasError = true;
        }
        if (!stock.size || !stock.size.sizeId) {
          productStockError.size = t("errors.requiredField");
          hasError = true;
        }
        if (!stock.price) {
          productStockError.price = t("errors.requiredField");
          hasError = true;
        }
        if (!stock.boxCondition) {
          productStockError.boxCondition = t("errors.requiredField");
          hasError = true;
        }
        productError.productDetails.productStocks.push(productStockError);
      });
      errors.push(productError);
    });
    setProductListError(errors);
    return hasError;
  }, [productList, t]);

  const updateProduct = (index: number, product: Product) => {
    setProductList((productList) => {
      const newList = [...productList];
      newList[index] = product;
      return newList;
    });
  };

  const loadFilterContent = async (props: {
    sneakerId?: string;
    storeId?: string;
    liked?: boolean;
  }) => {
    const { sneakerId, storeId, liked } = props;

    try {
      const filterContent: AxiosResponse<IFilterContent> =
        await Api.contentManagementApi.getFilterContent(
          sneakerId as string,
          storeId as string,
          liked
        );

      setFilterContent(filterContent.data);
    } catch (e) {
      console.warn(e);
    }
  };

  const loadAddressList = useCallback(async () => {
    // TODO: https://nextjs.org/docs/authentication add authentication session to make this call in getServerSideProps
    try {
      const addressList: AxiosResponse<IAddress[]> =
        await Api.userApiInstance.getAddressList();
      setAddressList(addressList.data);
    } catch (e) {}
  }, []);

  const setFavoriteAddress = async (address: IAddress) => {
    try {
      if (!address.addressId) {
        return;
      }
      setAddressList(
        addressList.map((addr) => {
          return {
            ...addr,
            favorite: addr.addressId === address.addressId,
          };
        })
      );
      await Api.userApiInstance.setFavoriteAddress(address.addressId);
    } catch (e) {
      console.error(e);
    } finally {
      loadAddressList();
    }
  };

  const deleteAddress = async (address: IAddress) => {
    try {
      if (!address.addressId) {
        return;
      }
      setAddressList(
        addressList.filter((addr) => addr.addressId !== address.addressId)
      );
      await Api.userApiInstance.deleteAddress(address.addressId);
    } catch (e) {
      console.error(e);
    } finally {
      loadAddressList();
    }
  };
  useEffect(() => {
    if (router.pathname.includes("store")) {
      loadFilterContent({ storeId: router?.query?.slug as string });
    } else if (router.pathname.includes("sneaker") && router?.query?.slug) {
      loadFilterContent({ sneakerId: router?.query?.slug as string });
    } else if (router.pathname.includes("wish")) {
      loadFilterContent({ liked: true });
    } else if (
      router.pathname.includes("sneakers") ||
      router.pathname === "/"
    ) {
      loadFilterContent({});
    }
  }, [router.pathname, router?.query?.slug]);

  return (
    <SneakersContext.Provider
      value={
        {
          sneakerListPaged,
          setSneakerListPaged,
          filterContent,
          setFilterContent,
          selectedFilters,
          setSelectedFilters,
          sneaker,
          setSneaker,
          product,
          setProduct,
          productStock,
          setProductStock,
          brandMap,
          productList,
          setProductList,
          addProduct,
          removeProduct,
          updateProduct,
          productPicturesModal,
          setProductPicturesModal,
          sizesMap,
          addressList,
          setAddressList,
          loadAddressList,
          setSelectedAddress,
          selectedAddress,
          setFavoriteAddress,
          deleteAddress,
          createOrderStatus,
          setCreateOrderStatus,
          order,
          setOrder,
          setIsProductEditMode,
          isProductEditMode,
          sizes,
          setSizes,
          loadFilterContent,
          productListError,
          setProductListError,
          validateProductList,
          brands,
          setBrands,
          fullBrandMap,
          fullSizesMap,
        } as ISneakersContext
      }
    >
      {children}
    </SneakersContext.Provider>
  );
};

export default SneakersProvider;
