import isEmail from "validator/lib/isEmail";

export const toCamelCase = function (string: any) {
  let value;
  if (string.includes(" ")) {
    value = string
      .split(" ")
      .reduce(
        (a: any, b: any) =>
          a.toLowerCase() + b.charAt(0).toUpperCase() + b.slice(1)
      );
  } else value = string.toLowerCase();
  return value;
};

export const validateEmail = (email = "") => {
  return isEmail(email);
};

export const addSpaceAfterUppercaseLetters = (inputString: string) =>
  inputString.replace(/([a-z])([A-Z])/g, "$1 $2");

export const replaceUnderlineWithSpace = (inputString: string) =>
  inputString.replace(/_/g, " ");
