import TextInput from "components/common/atoms/TextInput";
import React, { useState, useContext } from "react";
import styles from "./AuthModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { ILogin } from "./types";
import { validateEmail } from "helpers/functions";
import { registerUser } from "api/auth";
import Button from "components/common/atoms/Button";
import { GeneralContext } from "contexts/GeneralContext";
import { signIn } from "next-auth/react";

const Login = (props: ILogin) => {
  const { setType } = props;

  const { t } = useContext(GeneralContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [inputErrors, setInputErrors] = useState({
    email: "",
    password: "",
    confirmedPassword: "",
    firstName: "",
    lastName: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    const inputsErrorsList = {
      email: "",
      password: "",
      confirmedPassword: "",
      firstName: "",
      lastName: "",
    };

    if (firstName === "") {
      inputsErrorsList.firstName = t("errors.requiredField");
    } else if (firstName.length > 50) {
      inputsErrorsList.firstName = t("errors.maxLength50");
    }

    if (lastName === "") {
      inputsErrorsList.lastName = t("errors.requiredField");
    } else if (firstName.length > 50) {
      inputsErrorsList.lastName = t("errors.maxLength50");
    }

    if (email === "") {
      inputsErrorsList.email = t("errors.requiredField");
    } else if (!validateEmail(email)) {
      inputsErrorsList.email = t("errors.invalidEmail");
    }

    if (password === "") {
      inputsErrorsList.password = t("errors.requiredField");
    } else if (password.length < 8) {
      inputsErrorsList.password = t("errors.invalidPassword");
    } else if (password !== confirmedPassword) {
      inputsErrorsList.password = t("errors.matchPassword");
    }

    if (confirmedPassword === "") {
      inputsErrorsList.confirmedPassword = t("errors.requiredField");
    } else if (confirmedPassword.length < 8) {
      inputsErrorsList.confirmedPassword = t("errors.invalidPassword");
    } else if (password !== confirmedPassword) {
      inputsErrorsList.confirmedPassword = t("errors.matchPassword");
    }

    setInputErrors(inputsErrorsList);

    if (Object.values(inputsErrorsList).some((value) => value !== "")) {
      return;
    } else {
      setIsLoading(true);
    }

    const response = await registerUser({
      email,
      password,
      firstName,
      lastName,
    });

    if (response) {
      setIsLoading(false);
      setType("confirmation");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setFirstName("");
      setLastName("");
    } else {
      setIsLoading(false);
    }
  };

  const handleKeyDown = async (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div className={styles["auth__title"]}>
        {t<string>("pages.register.title")}
      </div>
      <div className={styles["auth__description"]}>
        {t<string>("pages.register.subtitle")}
      </div>
      <form onSubmit={handleKeyDown}>
        <div className={styles["contact__form-name"]}>
          <TextInput
            hasBorder
            value={firstName}
            onChange={setFirstName}
            label={t("placeholders.firstName")}
            type="first name"
            required
            error={inputErrors.firstName}
          />
          <TextInput
            hasBorder
            value={lastName}
            onChange={setLastName}
            label={t("placeholders.lastName")}
            type="last name"
            required
            error={inputErrors.lastName}
          />
        </div>

        <TextInput
          hasBorder
          value={email}
          onChange={setEmail}
          label={t("placeholders.email")}
          type="email"
          required
          error={inputErrors.email}
        />
        <TextInput
          hasBorder
          value={password}
          onChange={setPassword}
          label={t("placeholders.password")}
          type="password"
          required
          error={inputErrors.password}
        />
        <TextInput
          hasBorder
          value={confirmedPassword}
          onChange={setConfirmPassword}
          label={t("placeholders.confirmPassword")}
          type="password"
          required
          error={inputErrors.confirmedPassword}
        />
        <Button
          isLoading={isLoading}
          buttonType="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {t<string>("buttons.createAccount")}
        </Button>
      </form>

      <div className={styles["auth__social"]}>
        <div className={styles["auth__social-title"]}>
          {t<string>("pages.register.orTry")}
        </div>
        <div className={styles["auth__social-container"]}>
          <Button
            onClick={() => signIn("facebook")}
            className={styles["auth__social-container--facebook"]}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Button>
          <Button
            onClick={() => signIn("google")}
            className={styles["auth__social-container--google"]}
          >
            <FontAwesomeIcon icon={faGoogle} />
          </Button>
        </div>
      </div>
      <div className={styles["auth__create"]}>
        <div className={styles["auth__create__title"]}>
          {t<string>("pages.register.alreadyAccount")}
        </div>
        <Button type="text" onClick={() => setType("login")}>
          {t<string>("buttons.login")}
        </Button>
      </div>
    </>
  );
};

export default Login;
