import Api from "api/apiInstances";
import { IProduct, ISneaker, IStore } from "api/openApi";
import { AxiosResponse } from "axios";
import { SneakersContext } from "contexts/SneakersContext";
import { StoreContext } from "contexts/StoreContext";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { IInitialProps } from "utils/CommonInterfaces";

export default function SsrHelper() {
  const { setSneaker, setProduct, setProductStock, setAddressList } =
    useContext(SneakersContext);

  const { setStoreContent } = useContext(StoreContext);

  const [history, setHistory] = useState<string[]>([]);
  const router = useRouter();

  useEffect(() => {
    onChangeContext();
    setHistory((previous) => [...previous, router.route]);
  }, [router.route]);

  const onChangeContext = async () => {
    if (history.length) {
      const props = await getInitialPropsFromServer(router);
      props.sneaker && setSneaker(props.sneaker);
      props.product && setProduct(props.product);
      props.productStock && setProductStock(props.productStock);
      props.addressList && setAddressList(props.addressList);

      props.storeData &&
        setStoreContent({
          ...props.storeData,
          logo: null,
          banner: null,
        });
    }
    setHistory((previous) => [...previous, router.route]);
  };

  return <></>;
}
export async function getInitialPropsFromServer(
  router: any
): Promise<IInitialProps> {
  const route = router?.route;
  const slug = router?.query?.slug;
  const productStockId = router?.query?.productStockId;
  const props: Partial<IInitialProps> = {
    sneaker: undefined,
    product: undefined,
    productStock: undefined,
    addressList: undefined,
    storeData: undefined,
  };

  if (
    slug &&
    [
      "/product/[slug]",
      "product/create-order/[slug]",
      "admin/product/[slug]",
    ].find((item) => route.includes(item))
  ) {
    try {
      const product: AxiosResponse<IProduct> =
        await Api.productsApiInstance.getProductById(slug as string);
      props.product = product?.data;

      const sneaker: AxiosResponse<ISneaker> =
        await Api.sneakerApiInstance.getSneakerById(
          product.data.sneakerId as string
        );
      props.sneaker = sneaker?.data;

      if (product.data.productSeller?.storeId) {
        const store: AxiosResponse<IStore> =
          await Api.storeApiInstance.getStoreById(
            product.data.productSeller?.storeId as string
          );
        props.storeData = {
          ...store?.data,
          logo: null,
          banner: null,
        };
      }
    } catch (e: any) {
      console.error("error", e.message);
    }
  } else if (
    slug &&
    ["account/add-product/[slug]"].find((item) => route.includes(item))
  ) {
    try {
      const sneaker: AxiosResponse<ISneaker> =
        await Api.sneakerApiInstance.getSneakerById(slug as string);
      props.sneaker = sneaker.data as ISneaker;
    } catch (e) { }
  } else if (slug && ["/store/[slug]"].find((item) => route.includes(item))) {
    try {
      const store: AxiosResponse<IStore> =
        await Api.storeApiInstance.getStoreById(slug);
      props.storeData = {
        ...store?.data,
        logo: null,
        banner: null,
      };
    } catch (err) {
      console.log(err);
    }
  }

  if (props.product && productStockId) {
    try {
      const productStock = props?.product?.productStocks?.find(
        (item) => item.productStockId === productStockId
      );
      props.productStock = productStock;
    } catch (e: any) {
      console.error(e.message);
    }
  }

  return props;
}
