import React, { createContext, useState, useEffect, useMemo } from "react";
import {
  WS_BASE_URL,
  setupInterceptors,
  unsetAccessTokenAttachedToAxiosDefaults,
} from "api/axiosInstance";
import Api from "api/apiInstances";
import { signIn, signOut, useSession } from "next-auth/react";
import {
  IJwtAuthenticationRequest,
  INotification,
  INotificationPaged,
  ISizeUoMEnum,
  IUserPreference,
} from "api/openApi";
import { AxiosResponse } from "axios";
import { User } from "next-auth";
import AuthModal from "components/common/Authentification/AuthModal";
import { useRouter } from "next/router";
import { RxStomp } from "@stomp/rx-stomp";
import { IWebSocketEvent } from "components/messages/ChatNew/types";
import { Subscription } from "rxjs";

export const AuthContext = createContext({} as any);

const AuthProvider = (props: any) => {
  const { data } = useSession();
  const [userInfo, setUserInfo] = useState<User>({
    id: "",
    firstName: "",
    lastName: "",
    picture: "",
    userId: "",
    email: "",
    iat: new Date(),
  });

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [notifications, setNotifications] = useState<INotificationPaged>();
  const [pushNotification, setPushNotification] = useState<INotification>();
  const [wsClient, setWsClient] = useState<RxStomp>();
  const [roomSubscriptions, setRoomSubscriptions] = useState<Subscription[]>(
    []
  );

  const router = useRouter();

  const [userPreference, setUserPreference] = useState<IUserPreference>({
    sizeUoM: ISizeUoMEnum.Eu,
  });
  const [authModalType, setAuthModalType] = useState<
    "login" | "register" | "reset" | "confirmation"
  >("login");

  const tokens = useMemo(() => {
    return data?.tokens;
  }, [data?.tokens]);

  useEffect(() => {
    setupInterceptors(tokens);
  }, [tokens]);

  const onLogout = () => {
    signOut({
      redirect: false,
    }).then(() => {
      unsetAccessTokenAttachedToAxiosDefaults();
      router.push("/");
    });
  };

  const onLogin = async (data: IJwtAuthenticationRequest) => {
    const response = await signIn("credentials", { ...data, redirect: false });
    return response;
  };

  const onLoginOTC = async (token: string) => {
    const response = await signIn("otc", { token, redirect: false });
    return response;
  };

  const onLoginResetPassword = async (token: string, password: string) => {
    const response = await signIn("reset-passord", {
      token,
      password,
      redirect: false,
    });
    return response;
  };

  const updateIsAuthModalOpen = (isOpen: boolean) => {
    if (isLoggedIn) {
      setIsAuthModalOpen(false);
    } else {
      setIsAuthModalOpen(isOpen);
    }
  };

  const isLoggedIn = useMemo(() => {
    return !!data?.user;
  }, [data?.user]);

  useEffect(() => {
    if (data?.user) {
      setUserInfo(data.user);
    }
  }, [data?.user]);

  useEffect(() => {
    if (isLoggedIn) {
      const getNotifications = async () => {
        try {
          const response =
            await Api.notificationsApiInstance.getUserNotification(0, 20);
          setNotifications(response.data);
        } catch (err) {
          console.log(err);
        }
      };

      const loadUserPreferences = async () => {
        try {
          const userPreference: AxiosResponse<IUserPreference> =
            await Api.userApiInstance.getUserPreference();
          setUserPreference(userPreference.data);
        } catch (e) {
          console.warn(e);
        }
      };

      loadUserPreferences();
      getNotifications();

      if (!wsClient || !wsClient?.active) {
        initializeWebsocket();
      }
    }
  }, [isLoggedIn, data?.tokens.accessToken]);

  const getRealtimeNotificationData = (event: IWebSocketEvent) => {
    const notification = event.body as INotification;
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      list: [notification, ...(prevNotifications?.list || [])],
    }));
    setPushNotification(notification);
  };

  const initializeWebsocket = () => {
    console.log("🚀 ~ initializeWebsocket ~ WS_BASE_URL:", WS_BASE_URL);
    console.log("🚀 ~ initializeWebsocket ~ tokens:", tokens);
    const localClient = new RxStomp();
    localClient.configure({
      brokerURL: WS_BASE_URL,
      connectHeaders: {
        Authorization: `Bearer ${tokens?.accessToken}`,
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      // onConnect: onConnected,
      // onDisconnect: onDisconnected,
    });

    setWsClient(localClient);

    localClient.activate();
    localClient
      .watch({ destination: "/user/topic/notification" })
      .subscribe((message) => {
        if (message.body) {
          const wsMessage = JSON.parse(message.body) as IWebSocketEvent;
          if (wsMessage) {
            getRealtimeNotificationData(wsMessage);
          }
        }
      });
  };

  const isConnectedToWS = useMemo(() => {
    return wsClient?.connected$;
  }, [wsClient]);

  const subscribeToRoom = (room: string) => {
    const localRoomSubscription = wsClient
      ?.watch({
        destination: `/user/topic/room/${room}`,
      })
      .subscribe((message: any) => {
        if (message.body) {
          const event = JSON.parse(message.body) as IWebSocketEvent;
          if (event) {
            const customEvent = new CustomEvent(room, {
              detail: event,
            });
            window.dispatchEvent(customEvent);
            // handleIncommingMessage(event, room);
          }
        }
      });
    console.log(
      "🚀 ~ AuthProvider ~ localRoomSubscription:",
      localRoomSubscription
    );
    if (!localRoomSubscription) return;
    setRoomSubscriptions((prev) => [...prev, localRoomSubscription]);
  };

  const unsubscribeFromRooms = () => {
    console.log(
      "🚀 ~ unsubscribeFromRooms ~ unsubscribeFromRooms:",
      roomSubscriptions
    );

    roomSubscriptions.forEach((subscription) => {
      console.log(
        "🚀 ~ roomSubscriptions.forEach ~ roomSubscriptions:",
        subscription
      );
      subscription.unsubscribe();
    });
    setRoomSubscriptions([]);
  };

  const publishToRoom = (room: string, event: IWebSocketEvent) => {
    wsClient?.publish({
      destination: `/app/topic/room/${room}`,
      body: JSON.stringify(event),
    });
  };

  return (
    <AuthContext.Provider
      value={
        {
          onLogout,
          onLogin,
          userInfo,
          isAuthModalOpen,
          updateIsAuthModalOpen,
          notifications,
          onLoginOTC,
          userPreference,
          setUserPreference,
          authModalType,
          setAuthModalType,
          isLoggedIn,
          pushNotification,
          onLoginResetPassword,
          tokens,
          subscribeToRoom,
          unsubscribeFromRooms,
          publishToRoom,
          isConnectedToWS,
        } as any
      }
    >
      <AuthModal
        isOpen={isAuthModalOpen}
        setIsOpen={setIsAuthModalOpen}
        setType={setAuthModalType}
        type={authModalType}
      />
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
