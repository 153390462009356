import React from "react";
import IMediaIcon from "./types";
import styles from "./MediaIcon.module.scss";
import cn from "classnames";

const MediaIcon = (props: IMediaIcon) => {
  const { url, alt, Icon, target, text } = props;

  return (
    <a
      target={target}
      className={cn(styles["media-icon"], {
        [styles["media-icon--with-text"] as string]: text,
      })}
      aria-label={alt}
      href={url}
      rel="noreferrer"
    >
      <Icon />
      {text && <span>{text}</span>}
    </a>
  );
};

export default MediaIcon;
