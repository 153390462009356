import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getSession } from "next-auth/react";

export const WS_BASE_URL = process.env.REACT_APP_WS_BASE
  ? process.env.REACT_APP_WS_BASE
  : process.env.NEXT_PUBLIC_WS_BASE;

export const BASE_URL = process.env.REACT_APP_API_BASE
  ? process.env.REACT_APP_API_BASE
  : process.env.NEXT_PUBLIC_API_BASE;

const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
});

let requestInterceptor: number;

const unauthenticatedPaths = [
  "/authentication/login",
  "/authentication/register",
  "/authentication/oauthLogin",
  "/authentication/oauthRegister",
  "/authentication/forgot-password",
  "/authentication/reset-password",
];
const refreshPath = "/authentication/refresh";

function isUnauthenticatedEndpoint(url: string | undefined) {
  return unauthenticatedPaths.find((path) => url && url.includes(path));
}

export const setupInterceptors = async (tokens: any) => {
  if (tokens) {
    const session = await getSession();
    tokens = session?.tokens;
  }
  if (requestInterceptor !== undefined) {
    instance.interceptors.request.eject(requestInterceptor);
  }

  requestInterceptor = instance.interceptors.request.use(async (request) => {
    await setAccessTokenOnRequestAndAsAxiosDefaults(request, tokens);
    return request;
  });
};

const isAccessTokenAttachedToAxiosDefaults = () => {
  const authHeader = instance.defaults.headers.common["Authorization"];
  if (authHeader === null || authHeader === undefined || authHeader === "")
    return false;
  else return true;
};

const setAccessTokenOnRequestAndAsAxiosDefaults = async (
  request: AxiosRequestConfig,
  tokens: any
) => {
  let AuthHeaderValue;

  if (
    !isUnauthenticatedEndpoint(request.url) &&
    !isAccessTokenAttachedToAxiosDefaults() &&
    !request.url?.includes(refreshPath)
  ) {
    if (tokens) {
      AuthHeaderValue = `Bearer ${tokens.accessToken}`;
    } else {
      const session = await getSession();
      if (session && session.tokens) {
        AuthHeaderValue = `Bearer ${session.tokens.accessToken}`;
      }
    }
    if (AuthHeaderValue) {
      if (!request.headers) request.headers = {};
      request.headers.Authorization = AuthHeaderValue;
      instance.defaults.headers.common["Authorization"] = AuthHeaderValue;
    }
    return;
  }
};

export const unsetAccessTokenAttachedToAxiosDefaults = () => {
  delete instance.defaults.headers.common["Authorization"];
};

export default instance;
