import React from "react";
import Modal from "react-bootstrap/Modal";
import IAuthModal from "./types";
import styles from "./AuthModal.module.scss";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import { useRouter } from "next/router";
import EmailConfirmation from "./EmailConfirmation";

const AuthModal = (props: IAuthModal) => {
  const { type, isOpen, setIsOpen, setType } = props;
  const router = useRouter();

  const handleClose = () => {
    if (router.asPath.includes("callbackUrl")) {
      router.asPath = "";
    }
    setIsOpen(false);
  };

  const authComponent = () => {
    switch (type) {
      case "login":
        return <Login handleClose={handleClose} setType={setType} />;
      case "register":
        return <Register handleClose={handleClose} setType={setType} />;
      case "reset":
        return <Reset handleClose={handleClose} setType={setType} />;
      case "confirmation":
        return (
          <EmailConfirmation handleClose={handleClose} setType={setType} />
        );
      default:
        return <></>;
    }
  };

  return (
    <Modal centered show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className={styles["auth__body"]}>
        {authComponent()}
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
