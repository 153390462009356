import {
  IFilterContent,
  ISneakerListPaged,
  IProductFilter,
  ISneaker,
  IBrand,
  IProductConditionEnum,
  ISizeDTO,
  IProduct,
  IProductStock,
  IAddress,
  IOrder,
  IStore,
  ISubscription,
} from "api/openApi/api";
import { IAddProduct, Product, ProductError } from "utils/CommonInterfaces";
import { TFunction, i18n } from "i18next";

export interface ISneakersContext {
  sneakerListPaged?: ISneakerListPaged;
  setSneakerListPaged: (sneakers: ISneakerListPaged) => void;
  filterContent: IFilterContent;
  setFilterContent: (filterContent: IFilterContent) => void;
  selectedFilters: IProductFilter;
  setSelectedFilters: (filterContent: IProductFilter) => void;
  sneaker: ISneaker;
  setSneaker: (sneaker: ISneaker) => void;
  product: IProduct;
  setProduct: (product: IProduct) => void;
  productStock: IProductStock;
  setProductStock: (product: IProductStock) => void;
  brandMap: Array<IBrand>;
  sizesMap: { [key: number]: ISizeDTO };
  fullBrandMap: Array<IBrand>;
  fullSizesMap: { [key: number]: ISizeDTO };
  productList: Product[];
  productListError: ProductError[];
  setProductListError: (error: ProductError[]) => void;

  setProductList: (sneaker: React.SetStateAction<Product[]>) => void;
  addProduct: (condition: IProductConditionEnum) => void;
  updateProduct: (index: number, product: Product) => void;
  removeProduct: (index: number) => void;

  productPicturesModal: {
    isOpen: boolean;
    addProduct: IAddProduct;
  };
  setProductPicturesModal: (data: {
    isOpen: boolean;
    addProduct: IAddProduct;
  }) => void;

  setAddressList: (addressList: IAddress[]) => void;
  addressList: IAddress[];
  loadAddressList: () => void;
  selectedAddress: IAddress;
  setSelectedAddress: (address: IAddress) => void;
  setFavoriteAddress: (address: IAddress) => void;
  deleteAddress: (address: IAddress) => void;

  createOrderStatus: { status: CreateOrderStatus; message?: string };
  setCreateOrderStatus: (data: {
    status: CreateOrderStatus;
    message?: string;
  }) => void;

  order: IOrder;
  setOrder: (order: IOrder) => void;

  isProductEditMode: boolean;
  setIsProductEditMode: (isEditMode: boolean) => void;

  sizes: ISizeDTO[];
  setSizes: (sizes: ISizeDTO[]) => void;

  loadFilterContent: (sneakerId?: string, storeId?: string) => Promise<void>;
  validateProductList: () => boolean;
}

export enum CreateOrderStatus {
  Success = "success",
  InProgress = "inProgress",
  Error = "error",
  OutOfStock = "outOfStock",
}
export interface IGeneralContext {
  brands?: Array<{ name: string; photo: string }>;
  i18n: i18n;
  t: TFunction;
  openedDropdown: string;
  setOpenedDropdown: (value: string) => void;
  isMobileMenuOpen: boolean;
  isMobile: boolean;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  changeLanguage: (value: "en" | "ro") => void;
}

export interface IStoreExtended extends IStore {
  logo?: any;
  banner?: any;
}

export interface IStoreContext {
  storeContent?: IStoreExtended;
  setStoreContent: (store?: IStoreExtended) => void;
  isEditMode: boolean;
  setIsEditMode: (isEditMode?: boolean) => void;
  setStoreSubscription: (subscription?: ISubscription) => void;
  storeSubscription: ISubscription;
  stripeUrl: string;
  setStripeUrl: (stripeUrl?: string) => void;
  storePreviewType: "info" | "items";
  setStorePreviewType: (type: "info" | "items") => void;
  canAddProduct?: boolean;
  edditedStoreContent?: IStoreExtended;
  setEdditedStoreContent: (store?: IStoreExtended) => void;
  setView: (type: "info" | "items") => void;
}
