import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import { useTranslation } from "react-i18next";
import { IGeneralContext } from "./types";
import { useRouter } from "next/router";
import { IInitialProps } from "utils/CommonInterfaces";
export const GeneralContext = createContext({} as IGeneralContext);

const GeneralProvider = (
  props: PropsWithChildren<{
    initialContext: IInitialProps;
  }>
) => {
  const { i18n, t } = useTranslation();
  const [openedDropdown, setOpenedDropdown] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile] = useState(Boolean(props.initialContext?.isMobile));
  const router = useRouter();

  useEffect(() => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [router.pathname]);

  const changeLanguage = (lang: "en" | "ro") => {
    i18n.changeLanguage(lang);
  };

  return (
    <GeneralContext.Provider
      value={
        {
          i18n,
          t,
          openedDropdown,
          setOpenedDropdown,
          isMobileMenuOpen,
          isMobile,
          setIsMobileMenuOpen,
          changeLanguage,
        } as IGeneralContext
      }
    >
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralProvider;
