import { t } from "i18next";
import { ILogin } from "./types";
import styles from "./AuthModal.module.scss";
import Button from "components/common/atoms/Button";

const EmailConfirmation = (props: ILogin) => {
  const { handleClose } = props;

  return (
    <>
      <div className={styles["auth__title"]}>
        {`${t("pages.emailConfirmation.title")}`}
      </div>
      <div className={styles["auth__description"]}>
        {`${t("pages.emailConfirmation.subtitle")}`}
      </div>
      <Button onClick={handleClose} type="primary">
        {t("buttons.close")}
      </Button>
    </>
  );
};

export default EmailConfirmation;
