import TextInput from "components/common/atoms/TextInput";
import React, { useContext, useState } from "react";
import styles from "./AuthModal.module.scss";
import Button from "components/common/atoms/Button";
import Api from "api/apiInstances";
import { GeneralContext } from "contexts/GeneralContext";
import { ILogin } from "./types";
import { validateEmail } from "helpers/functions";

const Login = (props: ILogin) => {
  const { setType } = props;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const { t } = useContext(GeneralContext);

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
    if (email === "") {
      setEmailError(t("errors.requiredField"));
    } else if (!validateEmail(email)) {
      setEmailError(t("errors.invalidEmail"));
    }
    if (emailError) {
      return;
    }
    setIsLoading(true);
    try {
      await Api.authenticationApiInstance.forgotPassword({
        email,
        callbackRoute: "/reset-password",
      });
      setIsLoading(false);
      setType("confirmation");
      setEmailError("");
    } catch (error) {
      setIsLoading(false);
      setEmailError(t("errors.emailNotFound"));
    }
  };

  const handleKeyDown = async (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div className={styles["auth__title"]}>
        {`${t("pages.resetPassword.title")}`}
      </div>
      <div className={styles["auth__description"]}>
        {`${t("pages.resetPassword.subtitle")}`}
      </div>
      <form onSubmit={handleKeyDown}>
        <TextInput
          hasBorder
          value={email}
          onChange={setEmail}
          label="MAIL"
          type="email"
          error={emailError}
        />
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onSubmit}
          type="primary"
          buttonType="submit"
        >
          {t("pages.resetPassword.sendEmailButton")}
        </Button>
      </form>
    </>
  );
};

export default Login;
