import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import styles from "./Header.module.scss";
import Link from "next/link";
import MediaIcon from "components/common/atoms/MediaIcon";
import Authentification from "./Authentification";
import InstagramIcon from "assets/icons/instagram.svg";
import { useRouter } from "next/router";
import Logo from "assets/logo.svg";
import { IMediaIcon } from "./types";
import { GeneralContext } from "contexts/GeneralContext";
import Offcanvas from "react-bootstrap/Offcanvas";

export const mediaElements = [
  // {
  //   url: "/sneakers",
  //   alt: "Sneakers",
  //   Icon: SearchIcon,
  //   text: "Search sneakers",
  // },
  // {
  //   url: "https://www.facebook.com",
  //   alt: "Facebook",
  //   Icon: FacebookIcon,
  //   target: "_blank",
  // },
  {
    url: "https://www.instagram.com/_xkickx_",
    alt: "Instagram",
    Icon: InstagramIcon,
    target: "_blank",
  },
];

const displaySpecialHeaderPaths = [
  "/",
  "/terms-and-conditions",
  "/privacy-policy",
  "/cookies-policy",
  "/gdpr",
  "/contact",
];

const Header = () => {
  const router = useRouter();
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useContext(GeneralContext);

  const [isNavbarScrolled, setIsNavbarScrolled] = useState(false);
  const [isCityHeaderVisible, setIsCityHeaderVisible] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 85) {
      setIsNavbarScrolled(true);
    } else {
      setIsNavbarScrolled(false);
    }
  };

  useEffect(() => {
    if (displaySpecialHeaderPaths.includes(router.pathname)) {
      changeBackground();
      window.addEventListener("scroll", changeBackground);
      setIsCityHeaderVisible(true);
    } else {
      setIsNavbarScrolled(true);
      setIsCityHeaderVisible(false);
    }

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, [router.pathname]);

  return (
    <>
      <Navbar
        sticky="top"
        className={`${styles["header"]} ${
          isNavbarScrolled && styles["header--scrolled"]
        }`}
      >
        <Container className={styles["header__container"]}>
          {/* <BurgerIcon
            className={styles["header__icon-menu"]}
            onClick={toggleMenu}
            isOpen={isMobileMenuOpen}
          /> */}
          <Offcanvas
            show={isMobileMenuOpen}
            onClose={() => setIsMobileMenuOpen(false)}
            responsive="sm"
          >
            {/* <MobileMenu setIsMenuOpen={setIsMobileMenuOpen} /> */}
            <div
              className={styles["header__backdrop"]}
              onClick={() => setIsMobileMenuOpen(false)}
            />
          </Offcanvas>
          <Col className={styles["header__left"]}>
            <div className={styles["header__left-media"]}>
              {mediaElements.map((media: IMediaIcon, index: number) => {
                return <MediaIcon {...media} key={index} />;
              })}
            </div>
          </Col>
          <Col>
            <Link className={styles["header__logo"]} href="/">
              <Logo fill="#ff5858" />
              {/* <div>&quot;By sneakerheads for sneakersheads&quot;</div> */}
            </Link>
          </Col>
          <Col className={styles["header__right"]}>
            <Authentification />
          </Col>
        </Container>
      </Navbar>
      {isCityHeaderVisible && <div className={styles["background"]} />}
    </>
  );
};

export default Header;
