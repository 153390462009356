const en = {
  translation: {
    currencies: {
      ron: "RON",
      usd: "USD",
      eur: "EUR",
    },
    language: "en",
    basics: {
      and: "and",
      back: "back",
      products_one: "product",
      products_other: "products",
      info: "info",
      view: "view",
      sortBy: "sort by",
      stock: "Stock",
      edit: "Edit",
      open: "Open",
      status: "Status",
      delete: "Delete",
      save: "Save",
      filter: "Filter",
      results: "Results",
      addAddress: "Add address",
      yes: "Yes",
      no: "No",
      filters: "Filters",
      description: "Description",
      ok: "ok",
      error: "Error",
      cancel: "Cancel",
      sneakers: "Sneakers",
    },
    errors: {
      somethingWentWrong: "Something went wrong",
      server:
        "An error occurred, please try again. If the error still occurs, please contact support.",
      requiredField: "*Field required",
      requiredRating: "*Rating is required",
      invalidEmail: "*Invalid email address",
      invalidCredentials: "*Invalid credentials or server error",
      invalidPassword: "*Password should be longer than 8 characters",
      invalidPhone: "*Invalid phone format",
      invalidPostalCode: "*Invalid postal code format",
      matchPassword: "*Passwords doesn't match",
      maxLength50: "*This field must not have more than 50 characters",
      minLength20: "*This field must have at least 20 characters",
      minLength40: "*This field must have at least 40 characters",
      error: "Error",
      emailNotFound: "Email not found",
    },
    pages: {
      welcome: {
        validation: "Could not validate ",
        title: "Welcome to xKickx",
        activated: "Your account has been activated",
      },
      index: {
        exploreBrands: "Explore brands",
        howItWorks: "How it works?",
        step1:
          "First, search for the sneaker you wanna buy. Once you find it, you'll see a bunch of options with cool pics taken by the sellers. So, you can really check out the sneaker in detail.",
        step2:
          "Once you've picked the one you like, it's time to buy. You can go straight for it without any haggling, or if the seller's cool with it, you can even shoot them an offer.",
        step3:
          "After you've made your selection, if you wanna send a message to the seller, feel free to do so. You can even have a chat with them to get all the deets you need.",
        step4:
          "Once the seller gives the thumbs up to your order, they can either ship it straight to your door or set up a meet-up for you to grab it in person. Whatever floats your boat!",
        step5:
          "Now it's time to rock your awesome new kicks! Enjoy 'em to the max!",
      },
      settings: {
        title: "Settings",
        emailSection: "Email preferences",
        allowEmailOrderUpdates: "Emails about order updates",
        allowEmailNewProducts:
          "Emails about new products from wishlist (weekly)",
        allowEmailNewProductsInstant:
          "Emails about new products from wishlist (instantly)",
        allowEmailNewSneakers: "Emails about new sneakers and promotions",
        apparelSection: "Apparel preferences",
        sizeBrandSection: "Size brand preferences",
      },
      myProfile: {
        addresses: "Addresses",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phone: "Phone",
        phoneNumber: "Phone",
        name: "Name",
        aboutUs: "About us",
        facebookAccount: "Facebook",
        instagramAccount: "Instagram",
      },
      address: {
        title: "Add address",
        confirmTitle: "Confirm",
        deleteAddress: "Are you sure you want to delete this address?",
      },
      reportAsFake: {
        title: "Report as fake",
        subTitle:
          "Enter your reasons why you consider as fake and xkickx team will do further investigations",
      },
      orderAwb: {
        title: "Order information",
        subTitle: "Please add your order information",
      },
      orderInfo: {
        reject: "Reject",
        approve: "Approve",
        done: "Done",
        inDelivery: "In delivery",
        cancel: "Cancel",
        box: "Box",
        delivery: "Delivery",
        phone: "Phone",
        paymentType: "Payment type",
        deliveryAddress: "Delivery address",
        awb: "Awb",
        courier: "Courier",
        offeredPrice: "Price offered",
        status: "Order status",
        return: "Return",
      },
      chatList: {
        offers: "Offers",
      },
      addProduct: {
        newTitle: "New products",
        usedTitle: "Used products",
        confirmTitle: "Confirm",
        updateDescription: "Are you sure you want to update the product?",
        addDescription: "Are you sure you want to add the product?",
        deleteDescription: "Are you sure you want to delete the product?",
        addNew: "Add new product",
        addUsed: "Add used product",
        newInfo:
          "For new products it is enough to fill the details only once regardless of pair number",
        usedInfo:
          "For used products you have to fill the details for each pair",
        addProduct: "Add Sneaker",
        editProduct: "Edit product",
        searchSneakerSubtitle: "Search product you want to add into your store",
        updateTitle: "Update and publish",
        updateSubtitle:
          "Update info about your product and publish in your store",
        addTitle: "Fill and publish",
        addSubtitle:
          "Fill in the info about your product and publish in your store",
        noSubscriptionTitle:
          "You can't add more products for your subscription",
        noSubscriptionSubtitle:
          "If you want ot add more, go back to your subscription settings ",
        noSubscriptionLink: "by using this link",
        orderCreated: "The order was created successfully.",
        soldOut: "Sold out",
        buyProduct: "Buy product",
        negotiateProduct: "Negotiate product",
      },
      createOrder: {
        enterDataToBuy: "Enter your data to buy the product",
        chatCreated:
          "A chat has been created with the seller in the link below to discuss more details if necessary",
        selectAddress: "Select an address",
        proposeAmount: "Propose an amount",
        deliveryType: "Delivery type",
        paymentType: "Payment type",
      },
      store: {
        bannerName: "xkickx collaborator",
        seeProducts: "See products",
        publicData: "Public data",
        storeData: "Store data",
        socialMedia: "Social media",
        reviews: "Reviews",
        noStore: "You don't have a store yet!",
        products: "Products",
      },
      subscriptions: {
        name: "Subscriptions",
        month: "Monthly",
        year: "Yearly",
        metrics: "Store metrics",
        logo: "Custom logo",
        banner: "Custom banner",
        includedItems: "Maximum {{items}} products at sale",
        unlimited: "Unlimited products at sale",
        paymentmonth: "month",
        paymentyear: "year",
      },
      order: {
        currentTab: "Current",
        historyTab: "History",
        negotiable: "Negotiable",
        notNegotiable: "Not negotiable",
        bestOffer: "Best offer ({{countOffer}})",
        myOffer: "My offer",
        created: "Created at",
        updated: "Updated at",
        noUpdate: "No update",
      },
      profile: {
        title: "Personal Data",
      },
      login: {
        title: "Welcome!",
        subtitle: "Please add your credentials below",
        needRegister: "Don't have an account?",
        forgotPassword: "Forgot your password?",
        orTry: "Or you can try",
      },
      register: {
        title: "Create account",
        subtitle: "Please add your information below",
        alreadyAccount: "Already have an account?",
        login: "Login here",
        checkbox: "By creating an account you agree to our",
        orTry: "Or you can try",
      },
      product: {
        availableSizes: "Available sizes",
        colorway: "Colorway",
        releaseYear: "Release year",
        styleId: "Style id",
        notReleased: "Not released",
        findMySize: "Find my size",
        invalidSubscription:
          "You can't add more products with current subscription",
        addProduct: "Add Sneaker",
      },

      resetPassword: {
        title: "Reset password",
        subtitle: "Please add your information below",
        sendEmailButton: "Send email",
        validation: "Could not validate ",
      },
      emailConfirmation: {
        title: "Email sent",
        subtitle:
          "An email has been sent to your email address with further instructions",
      },
      terms: {
        name: "terms",
        title: "Terms and conditions",
      },
      privacy: {
        name: "privacy",
        title: "Privacy policy",
      },
      cookies: {
        title: "Cookies policy",
      },
      gdpr: {
        title: "GDPR",
      },
      contact: {
        title: "Do you want to contact us?",
        subtitle:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Mollitia eum sint nam maxime impedit laboriosam iste, ipsam quas rem similique a veritatis in atque, autem molestias. Nihil ratione possimus natus.",
        responseMessage: "A person from our team will contact you soon",
        errorResponseMessage:
          "Could not send message, please contact us at contact@xkickx.com",
      },
      admin: {
        users: {
          phoneNumbers: "Phone number",
          role: "Role",
          created: "Created at",
          updated: "Updated at",
          noUpdate: "No update",
        },
        stores: {
          phoneNumbers: "Phone number",
          rating: "Rating (No)",
          phoneNumber: "Phone number",
          products: "Products",
          created: "Created at",
          updated: "Updated at",
          noUpdate: "No update",
        },
      },
    },
    labels: {
      message: "Message",
      referenceLink: "Add a reference link",
      courier: "Courier",
      awb: "Awb",
      country: "Country",
      county: "County",
      city: "City",
      address: "Address",
      postalCode: "Postal code",
      alias: "Alias",
      gender: "Gender",
      sizeUom: "Size unit",
      size: "Size",
      brand: "Brand",
      condition: "Condition",
      price: "Price",
      setAsFavorite: "Set as used address",
      favorite: "Used address",
      stock: "Stock",
      boxCondition: "Box condition",
      invoice: "Invoice",
      location: "Location",
      password: "Password",
      repeatPassword: "Repeat password",
      isNegotiable: "Is negotiable",
      hasInvoice: "Has invoice",
      productGrade: "Product grade: {{grade}}",
      description: "Description",
      proposeAmount: "Propose an amount",
      select: "Select",
      optionalMessage: "Write a message (optional)",
      personalDelivery: "Personal delivery",
      cash: "Cash",
      card: "Card",
    },
    toasts: {
      welcomeTitle: "Welcome to xKickx",
      welcomeBody: "You are now logged in",
      tokenErrorTitle: "Token error",
      tokenErrorBody: "Could not validate token",
      passwordResetTitle: "Password update",
      passwordResetBody: "Your password has been updated",
    },
    menus: {
      products: "My Sneakers",
      profile: "Profile",
      store: "Store",
      selling: "Orders (Selling)",
      buying: "Orders (Buying)",
      settings: "Settings",
      searchSneakers: "Search sneakers",
      wishlist: "Wishlist",
      howItWorks: "How it works",
      releases: "New releases",
    },
    placeholders: {
      email: "Email",
      password: "Password",
      firstName: "First Name",
      lastName: "Last Name",
      confirmPassword: "Confirm Password",
      size: "Size",
      stock: "Stock",
      shoeBox: "Shoe Box",
      invoice: "Invoice",
      location: "Location",
      negotiable: "Negotiable",
      search: "Search",
      phoneNumber: "Phone number",
      sneakerOfTheDay: "Sneaker of the day",
      productGrade: "Product grade",
    },
    buttons: {
      report: "Report",
      reportAsFake: "Report as fake",
      submit: "Save",
      clickHere: "Click here",
      login: "Login",
      register: "Register",
      registerHere: "Register here",
      createAccount: "Create account",
      negotiate: "Negotiate",
      buy: "Buy",
      approve: "Approve",
      reject: "Reject",
      addAddress: "Add address",
      logout: "Logout",
      addSize: "Add size",
      createStore: "Create store",
      viewFilteredSneakers: "View {{count}} sneakers",
      viewOffers: "View offers",
      viewOtherSneakers: "View other sneakers",
      send: "Send",
      addPicture: "Add picture",
      updatePassword: "Update password",
      seeProduct: "View product",
      finish: "Finish",
      seeOrder: "See order details",
      seeOrderList: "See order list",
      continueShopping: "Continue shopping",
      close: "Close",
    },
    validation: {
      required: "{{name}} is required!",
      notEmpty: "{{name}} cannot be empty!",
      type: "This is not a valid {{type}}!",
      range: "{{type}} must be between {{min}} and {{max}} characters",
    },
  },
};

export default en;
