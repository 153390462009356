const ro = {
  translation: {
    currencies: {
      ron: "RON",
      usd: "USD",
      eur: "EUR",
    },
    language: "ro",
    basics: {
      and: "si",
      back: "inapoi",
      products_one: "produs",
      products_few: "produse",
      products_other: "produse",
      info: "Informatii",
      view: "Vizualizeaza",
      sortBy: "sorteaza dupa",
      stock: "Stoc",
      edit: "Editeaza",
      open: "Deschide",
      status: "Status",
      delete: "Sterge",
      save: "Salveaza",
      filter: "Filtreaza",
      results: "Rezultate",
      addAddress: "Adauga adresa",
      yes: "Da",
      no: "Nu",
      filters: "Filtre",
      description: "Descriere",
      ok: "ok",
      error: "Eroare",
      cancel: "Renunta",
      sneakers: "Sneakers",
    },
    errors: {
      somethingWentWrong: "Ceva nu a mers bine",
      server:
        "A aparut o problema, incearca mai tarziu. Daca problema persista, te rugam sa ne contactezi.",
      requiredField: "*Camp obligatoriu",
      requiredRating: "*Scorul este obligatoriu",
      invalidEmail: "*Adresa de email invalida",
      invalidCredentials: "*Credentiale invalide sau o problema de la server",
      invalidPassword: "*Parola trebuie sa fie mai lunga de 8 caractere",
      invalidPhone: "*Formatul numarului de telefon invalid",
      invalidPostalCode: "*Formatul codului postal invalid",
      matchPassword: "*Parolele nu corespund",
      maxLength50: "*Acest camp nu trebuie sa fie mai mare de 50 de caractere",
      minLength20: "*Acest camp trebuie sa aiba cel putin 20 de caractere",
      minLength40: "*Acest camp trebuie sa aiba cel putin 40 de caractere",
      error: "Eroare",
      emailNotFound: "Emailul nu a fost gasit",
    },
    pages: {
      welcome: {
        validation: "Nu s-a putut valida ",
        title: "Bine ai venit in xKickx",
        activated: "Contul tau a fost activat",
      },
      index: {
        exploreBrands: "Exploreaza branduri",
        howItWorks: "Cum functioneaza?",
        step1:
          "Mai întâi, căutați adidașii pe care doriți să-l cumpărați. Odată ce îl găsiți, veți vedea o grămadă de opțiuni cu fotografii interesante făcute de vânzători. Deci, puteți verifica cu adevărat adidașii în detaliu.",
        step2:
          "Odată ce l-ați ales pe cel care vă place, este timpul să îl cumpărați. Puteți merge direct după el fără nicio tocmeală, sau dacă vânzătorului este rece cu el, puteți chiar să le trageți o ofertă.",
        step3:
          "După ce ați făcut selecția, dacă doriți să trimiteți un mesaj vânzătorului, nu ezitați să o faceți. Puteți chiar să discutați cu ei pentru a obține toate detaliile de care aveți nevoie.",
        step4:
          "Odată ce vânzătorul dă degetul în sus pentru comanda dvs., poate fie să o expedieze direct la ușa dvs., fie să stabilească o întâlnire pentru ca dvs. să o luați în persoană. Orice îți plutește barca!",
        step5:
          "Acum este timpul să-ți faci noile lovituri minunate! Bucură-te de ele la maximum!",
      },
      settings: {
        title: "Setari",
        emailSection: "Preferinte emailuri",
        allowEmailOrderUpdates: "Emailuri despre actualizari ale comenzilor",
        allowEmailNewProducts:
          "Emailuri cand apar produse noi din cele de la favorite (saptamanal)",
        allowEmailNewProductsInstant:
          "Emailuri cand apar produse noi din cele de la favorite (instant)",
        allowEmailNewSneakers:
          "Emailuri despre adidasii noi aparuti si promotii",
        apparelSection: "Preferinte imbracaminte",
        sizeBrandSection: "Preferinte marime branduri",
      },
      myProfile: {
        addresses: "Adrese",
        firstName: "Prenume",
        lastName: "Nume",
        email: "Email",
        phone: "Telefon",
        phoneNumber: "Telefon",
        name: "Nume",
        aboutUs: "Despre noi",
        facebookAccount: "Facebook",
        instagramAccount: "Instagram",
      },
      address: {
        title: "Adaugare adresa",
        confirmTitle: "Confirmare",
        deleteAddress: "Esti sigur ca vrei sa stergi adresa?",
      },
      reportAsFake: {
        title: "Raporteaza ca fake",
        subTitle:
          "Introdu motive pentru care consideri ca este fake si echipa xkickx va investiga mai departe",
      },
      orderAwb: {
        title: "Informatii comanda",
        subTitle: "Va rugam introduceti informatiile despre comanda",
      },
      orderInfo: {
        reject: "Refuza",
        approve: "Accepta",
        done: "Finalizeaza",
        inDelivery: "Livreaza",
        cancel: "Anuleaza",
        box: "Cutie",
        delivery: "Transport",
        phone: "Telefon",
        paymentType: "Modalitate plata",
        deliveryAddress: "Adresa de livrare",
        awb: "Awb",
        courier: "Curier",
        offeredPrice: "Pret oferit",
        status: "Status comanda",
        return: "Returneaza",
      },
      chatList: {
        offers: "Oferte",
      },
      addProduct: {
        newTitle: "Produse noi",
        usedTitle: "Produse folosite",
        confirmTitle: "Confirmare",
        updateDescription: "Esti sigura ca vrei sa modifici produsul?",
        addDescription: "Esti sigura ca vrei sa adaugi produsul?",
        deleteDescription: "Esti sigura ca vrei sa stergi produsul?",
        addNew: "Adauga produs nou",
        addUsed: "Adauga produs folosit",
        newInfo:
          "Pentru produsele noi este suficient sa adaugi detaliile o singura data indiferent de numarul de perechi",
        usedInfo:
          "Pentru produsele folosite va trebui sa adaugi detaliile pentru fiecare pereche in parte",
        addProduct: "Adaugare produs",
        editProduct: "Editeaza produs",
        searchSneakerSubtitle:
          "Cauta produsul pe care doresti sa il adaugi in magazinul tau",
        updateTitle: "Actualizeaza si publica",
        updateSubtitle:
          "Actualizeaza datele despre produsul tau si publica-l in magazin",
        addTitle: "Completeaza si publica",
        addSubtitle:
          "Completeaza datele despre produsul tau si publica-l in magazin",
        noSubscriptionTitle:
          "Nu mai poti adauga produse pentru abonamentul curent",
        noSubscriptionSubtitle:
          "Daca vrei sa adaugi mai multe produse, mergi la setarile pentru abonamente ",
        noSubscriptionLink: "folosind acest link",
        orderCreated: "Comanda a fost creata cu succes.",
        soldOut: "Stoc epuizat",
        buyProduct: "Cumpara produs",
        negotiateProduct: "Negociaza produs",
      },
      createOrder: {
        enterDataToBuy: "Introdu datele tale pentru a cumpara produsul",
        chatCreated:
          "S-a creat un chat cu vanzatorul in link-ul de mai jos pentru a discuta mai multe detalii daca este necesar",
        selectAddress: "Alege o adresa",
        proposeAmount: "Suma propusa",
        deliveryType: "Tipul livrarii",
        paymentType: "Metoda de plata",
      },
      store: {
        bannerName: "colaborator xkickx",
        seeProducts: "Vezi produse",
        publicData: "Date publice",
        storeData: "Date magazin",
        socialMedia: "Retele sociale",
        reviews: "Recenzii",
        noStore: "Nu ai inca un magazin!",
        products: "Produse",
      },
      subscriptions: {
        name: "Abonamente",
        month: "Lunar",
        year: "Anual",
        metrics: "Metrici magazin",
        logo: "Logo personalizat",
        banner: "Banner personalziat",
        includedItems: "Maxim {{items}} produse la vanzare",
        unlimited: "Produse nelimitate la vanzare",
        paymentmonth: "luna",
        paymentyear: "an",
      },
      order: {
        currentTab: "Current",
        historyTab: "Istoric",
        negotiable: "Negociabil",
        notNegotiable: "Pret fix",
        bestOffer: "Cea mai buna oferta ({{countOffer}})",
        myOffer: "Oferta mea",
        created: "Creat",
        updated: "Actualizat",
        noUpdate: "Fara actualizari",
      },
      profile: {
        title: "Date Personale",
      },
      login: {
        title: "Bine ai venit!",
        subtitle: "Adauga datele de conectare mai jos",
        needRegister: "Nu ai inca un cont?",
        forgotPassword: "Ai uitat parola?",
        orTry: "Sau poti folosi",
      },
      register: {
        title: "Creare cont",
        subtitle: "Adauga informatiile despre cont mai jos",
        alreadyAccount: "Ai deja un cont?",
        login: "Logheaza-te aici",
        checkbox: "Prin crearea unui cont esti de acord cu ",
        orTry: "Sau poti folosi",
      },
      product: {
        availableSizes: "Marimi disponibile",
        colorway: "Culoare",
        releaseYear: "An lansare",
        styleId: "Style id",
        notReleased: "Nelansat",
        findMySize: "Gaseste-mi marimea",
        invalidSubscription: "Nu mai poti adauga produse cu abonamentul curent",
        addProduct: "Adauga produs",
      },
      resetPassword: {
        title: "Reseteaza parola",
        subtitle: "Introdu adresa de email pentru a reseta parola",
        sendEmailButton: "Trimite email",
        validation: "Nu s-a putut valida ",
      },
      emailConfirmation: {
        title: "Email trimis",
        subtitle:
          "Un email a fost trimis la adresa de email introdusa. Verifica si folderul de spam.",
      },
      terms: {
        name: "teremenii nostrii",
        title: "Termeni si conditii",
      },
      privacy: {
        name: "privacy",
        title: "Privacy policy",
      },
      cookies: {
        title: "Cookies policy",
      },
      gdpr: {
        title: "GDPR",
      },
      contact: {
        title: "Vrei sa ne contactezi?",
        subtitle:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Mollitia eum sint nam maxime impedit laboriosam iste, ipsam quas rem similique a veritatis in atque, autem molestias. Nihil ratione possimus natus.",
        responseMessage:
          "O persoana din echipa xkickx te va contacta in curand",
        errorResponseMessage:
          "Nu am putut trimite mesajul te rugam sa ne contactezi la contact@xkickx.com",
      },
      admin: {
        users: {
          phoneNumbers: "Numar de telefon",
          role: "Rol",
          created: "Creat",
          updated: "Actualizat",
          noUpdate: "Fara actualizari",
        },
        stores: {
          phoneNumbers: "Numar de telefon",
          rating: "Scor (No)",
          phoneNumber: "Numar de telefon",
          products: "Produse",
          created: "Creat",
          updated: "Actualizat",
          noUpdate: "Fara actualizari",
        },
      },
    },
    labels: {
      message: "Mesaj",
      referenceLink: "Adauga un link de referinta",
      courier: "Curier",
      awb: "Awb",
      country: "Tara",
      county: "Judet",
      city: "Oras",
      address: "Adresa",
      postalCode: "Cod postal",
      alias: "Alias",
      gender: "Gen",
      sizeUom: "Unitate marime",
      size: "Marime",
      brand: "Brand",
      condition: "Conditie",
      price: "Pret",
      setAsFavorite: "Seteaza ca cea folosita",
      favorite: "Folosita",
      stock: "Stoc",
      boxCondition: "Stare cutie",
      invoice: "Factura",
      location: "Locatie",
      password: "Parola",
      repeatPassword: "Repeta parola",
      isNegotiable: "Este negociabil",
      hasInvoice: "Are factura",
      productGrade: "Nota produs: {{grade}}",
      description: "Descriere",
      proposeAmount: "Suma propusa",
      select: "Selecteaza",
      optionalMessage: "Scrie un mesaj (Optional)",
      personalDelivery: "Ridicare personala",
      cash: "Cash",
      card: "Card",
    },
    toasts: {
      welcomeTitle: "Bine ai venit",
      welcomeBody: "Contul tau a fost activat cu succes",
      tokenErrorTitle: "Eroare token",
      tokenErrorBody: "Tokenul este invalid",
      passwordResetTitle: "Parola actualizata",
      passwordResetBody: "Parola a fost actualizata cu succes",
    },
    menus: {
      products: "Produsele mele",
      profile: "Profilul meu",
      store: "Magazinul meu",
      selling: "Vanzare",
      buying: "Cumparare",
      settings: "Setari",
      searchSneakers: "Cauta adidasi",
      wishlist: "Favorite",
      howItWorks: "Cum functioneaza",
      releases: "Noi lansate",
    },
    placeholders: {
      email: "Email",
      password: "Parola",
      firstName: "Prenume",
      lastName: "Nume",
      confirmPassword: "Confirma parola",
      size: "Marime",
      stock: "Stoc",
      shoeBox: "Cutie",
      invoice: "Factura",
      location: "Locatie",
      negotiable: "Negociabil",
      search: "Cauta",
      phoneNumber: "Numar de telefon",
      sneakerOfTheDay: "Sneakersul zilei",
      productGrade: "Nota produs",
    },
    buttons: {
      report: "Raporteaza",
      reportAsFake: "Raporteaza ca fake",
      submit: "Salveaza",
      clickHere: "Click aici",
      login: "Logare",
      register: "Inregistreaza",
      registerHere: "Inregistreaza aici",
      createAccount: "Creaza cont",
      negotiate: "Negociaza",
      buy: "Cumpara",
      addAddress: "Adauga adresa",
      logout: "Delogare",
      addSize: "Adauga marime",
      createStore: "Creaza magazin",
      viewFilteredSneakers: "Vezi {{count}} sneakers",
      viewOffers: "Vezi oferte",
      viewOtherSneakers: "Vezi alti sneakers",
      send: "Trimite",
      addPicture: "Adauga poze",
      updatePassword: "Actualizeaza parola",
      seeProduct: "Vezi produs",
      finish: "Finalizeaza",
      seeOrder: "Vezi detaliile comenzii",
      seeOrderList: "Vezi lista de comenzi",
      continueShopping: "Continue cumparaturile",
      close: "Inchide",
    },
    validation: {
      required: "{{name}} este obligatoriu!",
      notEmpty: "{{name}} nu poate fi gol!",
      type: "Acesta nu este un {{type}} valid!",
      range: "{{type}} trebuie sa fie intre {{min}} si {{max}} caractere",
    },
  },
};

export default ro;
