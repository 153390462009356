import { AppProps } from "next/app";
import GeneralProvider from "contexts/GeneralContext";
import AuthProvider from "contexts/AuthContext";
import SneakersProvider from "contexts/SneakersContext";
import StoreProvider from "contexts/StoreContext";
import "i18n/i18n";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "components/common/Header";
import "../../styles/globals.scss";
import "../../styles/range.scss";
import { FilterContent, IInitialProps } from "utils/CommonInterfaces";
import SsrHelper, { getInitialPropsFromServer } from "components/SsrHelper";
import Script from "next/script";
import { SessionProvider } from "next-auth/react";
import { Session } from "next-auth";
import { NextPage } from "next";
import { ReactElement, ReactNode } from "react";
import Api from "api/apiInstances";

export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout;
};

const MyApp = ({
  Component,
  pageProps,
  ...extraProps
}: AppPropsWithLayout<{ session: Session }>) => {
  const { props } = extraProps as any;

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-HM297TRZZZ"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-HM297TRZZZ', {
            page_path: window.location.pathname,
            });
          `,
        }}
      />
      <SessionProvider
        session={pageProps?.session}
        // Re-fetch session every 5 minutes
        refetchInterval={5 * 60}
        // Re-fetches session when window is focused
        refetchOnWindowFocus={true}
      >
        <GeneralProvider initialContext={{ ...props }}>
          <AuthProvider>
            <SneakersProvider initialContext={{ ...props }}>
              <StoreProvider initialContext={{ ...props }}>
                <Header />
                <div className="app">
                  {getLayout(<Component {...pageProps} />)}
                </div>
                {/* <Footer /> */}
                <SsrHelper />
              </StoreProvider>
            </SneakersProvider>
          </AuthProvider>
        </GeneralProvider>
      </SessionProvider>
    </>
  );
};

MyApp.getInitialProps = async (nextProps: any) => {
  try {
    const brands = await Api.brandsApiInstance.getBrands();

    let props: IInitialProps = {
      filterContent: new FilterContent(),
      brands: brands.data,
    };
    const extraProps = await getInitialPropsFromServer(nextProps.router);

    const userAgent = nextProps.ctx?.req?.headers?.["user-agent"];
    const isMobile = Boolean(
      userAgent?.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    props = { ...props, ...extraProps, isMobile };

    return {
      props,
    };
  } catch (e) {
    return {
      props: {},
    };
  }
};

export default MyApp;
