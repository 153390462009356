import styles from "./Button.module.scss";
import { IButton } from "./types";
import cn from "classnames";
import { Spinner } from "react-bootstrap";

const Button = ({
  children,
  onClick,
  className,
  buttonType = "button",
  type = "default",
  disabled = false,
  isLoading = false,
}: IButton) => {
  let classType = "button--default";

  type ? (classType = `button--${type}`) : classType;

  const action = () => {
    if (disabled || isLoading) {
      return;
    } else {
      onClick && onClick();
    }
  };
  return (
    <button
      type={buttonType}
      onClick={action}
      disabled={disabled}
      className={cn(styles[classType], className, styles["button"])}
    >
      {isLoading ? <Spinner animation="border" /> : children}
    </button>
  );
};

export default Button;
