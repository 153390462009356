import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useRouter } from "next/router";
import React, { useState, forwardRef, useContext } from "react";
import styles from "./TextInput.module.scss";
import ITextInput from "./types";
import { GeneralContext } from "contexts/GeneralContext";

const TextInput = forwardRef<HTMLInputElement, ITextInput>((props, ref) => {
  const {
    value,
    onChange,
    label,
    type,
    hasBorder,
    required,
    error,
    className,
    onBlur,
    ariaLabel,
    placeholder,
    name,
    autocomplete,
    onFocus,
    onEnterPressRedirect,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();

  const { openedDropdown, setOpenedDropdown } = useContext(GeneralContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const prettierLabel =
    label.toLowerCase() === "instagramaccount"
      ? "INSTAGRAM"
      : label.toLowerCase() === "facebookaccount"
      ? "FACEBOOK"
      : label;

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (onEnterPressRedirect && e.key === "Enter") {
      if (onEnterPressRedirect.includes("sneakers"))
        router.push(`${onEnterPressRedirect}/?title=${value}`);
    }
  };

  const checkDropdown = () => {
    if (openedDropdown) setOpenedDropdown("");
  };

  return (
    <div className={cn(styles["container"], className)}>
      <input
        className={cn(styles["container__input"], {
          [styles["container--border"] as string]: hasBorder,
          [styles["container--border-error"] as string]: error,
        })}
        id={type ? type : "input"}
        type={showPassword ? "text" : type}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={checkDropdown}
        ref={ref}
        autoComplete={autocomplete || "on"}
        name={name}
        required={required}
        aria-label={ariaLabel}
        placeholder={placeholder}
      />
      <label
        className={cn(styles["container__label"], {
          [styles["container--filled"] as string]: value,
          [styles["container__label--error"] as string]: error,
        })}
        htmlFor={type ? type : "input"}
      >
        {prettierLabel}
      </label>
      {error && <span className={styles["container__error"]}>{error}</span>}
      {type === "password" && (
        <FontAwesomeIcon
          onClick={() => setShowPassword((previous) => !previous)}
          className={styles["container__eye"]}
          icon={showPassword ? faEyeSlash : faEye}
        />
      )}
    </div>
  );
});

TextInput.displayName = "TextInput";
export default TextInput;
