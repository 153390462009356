import { ISubscription } from "api/openApi";
import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
  useMemo,
} from "react";
import { IStoreContext, IStoreExtended } from "./types";
import { IInitialProps } from "utils/CommonInterfaces";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";

export const StoreContext = createContext({} as IStoreContext);

const StoreProvider = ({
  children,
  initialContext,
}: PropsWithChildren<{
  initialContext: IInitialProps;
}>) => {
  const [storeContent, setStoreContent] = useState<IStoreExtended>({
    ...initialContext?.storeData,
  });
  const [edditedStoreContent, setEdditedStoreContent] =
    useState<IStoreExtended>({
      ...initialContext?.storeData,
    });
  const [storeSubscription, setStoreSubscription] = useState<ISubscription>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [stripeUrl, setStripeUrl] = useState<string>();
  const [storePreviewType, setStorePreviewType] = useState<
    "info" | "items" | null
  >();
  const router = useRouter();

  const searchParams = useSearchParams();

  const isItems = searchParams.get("items");

  const setView = (type: "items" | "info") => {
    router.push(`/store/${storeContent.storeId}?${type}=true`);
  };

  useEffect(() => {
    if (router.pathname.includes("store")) {
      setStorePreviewType(isItems ? "items" : "info");
    }
  }, [router]);

  const canAddProduct = useMemo(() => {
    if (storeSubscription?.stripeSubscription?.includedItems === -1)
      return true;
    if (
      (storeSubscription?.usedItems || storeSubscription?.usedItems === 0) &&
      storeSubscription?.stripeSubscription?.includedItems
    ) {
      return (
        storeSubscription?.usedItems <
        storeSubscription?.stripeSubscription?.includedItems
      );
    } else {
      return undefined;
    }
  }, [storeSubscription]);

  useEffect(() => {
    if (isEditMode) {
      setIsEditMode(false);
    }
  }, [router.pathname]);

  return (
    <StoreContext.Provider
      value={
        {
          storeContent,
          setStoreContent,
          isEditMode,
          setIsEditMode,
          storeSubscription,
          setStoreSubscription,
          stripeUrl,
          setStripeUrl,
          storePreviewType,
          setStorePreviewType,
          canAddProduct,
          edditedStoreContent,
          setEdditedStoreContent,
          setView,
        } as IStoreContext
      }
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
