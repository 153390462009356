import {
  AuthenticationApi,
  ProductApi,
  SneakerApi,
  ContentManagementApi,
  UserApi,
  OrderApi,
  LocationApi,
  StoreApi,
  SubscriptionApi,
  NotificationApi,
  UserManagementApi,
  AdminApi,
  BrandApi,
  PhotoApi,
} from "./openApi";
import axiosInstance, { BASE_URL } from "./axiosInstance";

const Api = (url: string) => {
  const serverUrl = url;

  const authenticationApiInstance = new AuthenticationApi(
    undefined,
    serverUrl,
    axiosInstance
  );

  const storeApiInstance = new StoreApi(undefined, serverUrl, axiosInstance);

  const productsApiInstance = new ProductApi(
    undefined,
    serverUrl,
    axiosInstance
  );

  const userApiInstance = new UserApi(undefined, serverUrl, axiosInstance);

  const userManagementApiInstance = new UserManagementApi(
    undefined,
    serverUrl,
    axiosInstance
  );

  const contentManagementApi = new ContentManagementApi(
    undefined,
    serverUrl,
    axiosInstance
  );

  const locationApiInstance = new LocationApi(
    undefined,
    serverUrl,
    axiosInstance
  );

  const orderApiInstance = new OrderApi(undefined, serverUrl, axiosInstance);

  const sneakerApiInstance = new SneakerApi(undefined, url, axiosInstance);

  const notificationsApiInstance = new NotificationApi(
    undefined,
    serverUrl,
    axiosInstance
  );

  const subscriptionApiInstance = new SubscriptionApi(
    undefined,
    url,
    axiosInstance
  );

  const adminApiApiInstance = new AdminApi(undefined, url, axiosInstance);

  const brandsApiInstance = new BrandApi(undefined, url, axiosInstance);

  const photoApiInstance = new PhotoApi(undefined, url, axiosInstance);

  return {
    authenticationApiInstance,
    sneakerApiInstance,
    productsApiInstance,
    contentManagementApi,
    userApiInstance,
    orderApiInstance,
    locationApiInstance,
    storeApiInstance,
    subscriptionApiInstance,
    notificationsApiInstance,
    userManagementApiInstance,
    adminApiApiInstance,
    brandsApiInstance,
    photoApiInstance,
  };
};

export default Api(BASE_URL as string);
