import TextInput from "components/common/atoms/TextInput";
import React, { useState, useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { GeneralContext } from "contexts/GeneralContext";
import styles from "./AuthModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { ILogin } from "./types";
import { validateEmail } from "helpers/functions";
import Button from "components/common/atoms/Button";
import { signIn } from "next-auth/react";

const Login = (props: ILogin) => {
  const { setType, handleClose } = props;

  const { onLogin } = useContext(AuthContext);
  const { t } = useContext(GeneralContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputErrors, setInputErrors] = useState({
    email: "",
    password: "",
    button: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // TO DO: maybe move error logic outside of the function and do that inside of the TextInput component? It would be easier to
  // get the input to the default state when the user is typing

  const onSubmit = async () => {
    const inputsErrorsList = { email: "", password: "", button: "" };

    if (email === "") {
      inputsErrorsList.email = t("errors.requiredField");
    } else if (!validateEmail(email)) {
      inputsErrorsList.email = t("errors.invalidEmail");
    }

    if (password === "") {
      inputsErrorsList.password = t("errors.requiredField");
    } else if (password.length < 8) {
      inputsErrorsList.password = t("errors.invalidPassword");
    }

    setInputErrors(inputsErrorsList);

    if (Object.values(inputsErrorsList).some((value) => value !== "")) {
      return;
    } else {
      setIsLoading(true);
    }

    const response = await onLogin({ email, password });
    if (!response.error) {
      setIsLoading(false);
      setEmail("");
      setPassword("");
      handleClose();
    } else {
      setInputErrors({
        email: t("errors.invalidCredentials"),
        password: t("errors.invalidCredentials"),
        button: t("errors.invalidCredentials"),
      });
      setIsLoading(false);
    }
  };

  const handleKeyDown = async (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div className={styles["auth__title"]}>
        {t<string>("pages.login.title")}
      </div>
      <div className={styles["auth__description"]}>
        {t<string>("pages.login.subtitle")}
      </div>
      <form onSubmit={handleKeyDown}>
        <TextInput
          hasBorder
          value={email}
          onChange={setEmail}
          label={t("placeholders.email")}
          type="email"
          error={inputErrors.email}
        />
        <TextInput
          hasBorder
          value={password}
          onChange={setPassword}
          label={t("placeholders.password")}
          type="password"
          error={inputErrors.password}
        />
        <Button
          buttonType="submit"
          isLoading={isLoading}
          onClick={onSubmit}
          type={inputErrors.button ? "warning" : "primary"}
        >
          {t("buttons.login")}
        </Button>
      </form>

      <div className={styles["auth__forgot"]}>
        <label>{t<string>("pages.login.forgotPassword")}</label>
        <Button onClick={() => setType("reset")} type="text">
          {t<string>("buttons.clickHere")}
        </Button>
      </div>
      <div className={styles["auth__social"]}>
        <div className={styles["auth__social-title"]}>
          {t<string>("pages.login.orTry")}
        </div>
        <div className={styles["auth__social-container"]}>
          <Button
            onClick={() => signIn("facebook")}
            className={styles["auth__social-container--facebook"]}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Button>
          <Button
            onClick={() => signIn("google")}
            className={styles["auth__social-container--google"]}
          >
            <FontAwesomeIcon icon={faGoogle} />
          </Button>
        </div>
      </div>
      <div className={styles["auth__create"]}>
        <div className={styles["auth__create__title"]}>
          {t<string>("pages.login.needRegister")}
        </div>
        <Button onClick={() => setType("register")} type="text">
          {t<string>("buttons.registerHere")}
        </Button>
      </div>
    </>
  );
};

export default Login;
